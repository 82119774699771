import PropTypes from "prop-types";
import { ListSubheader, MenuItem } from "@mui/material";
const NonBrandItem = ({ brandName, setPreferredBrand}) => {
    if (brandName === 'Other') {
      return (
        <>
          <ListSubheader sx={{ fontWeight: 'bold' }}>Other</ListSubheader>
          <MenuItem
            onClick={() => setPreferredBrand(null)}
            key={brandName}
            sx={{ ml: 1 }}
          >
            Other Brands
          </MenuItem>
        </>
      );
    }
  
    return <ListSubheader sx={{ fontWeight: 'bold' }}>{brandName}</ListSubheader>;
  };

  NonBrandItem.propTypes = {
    brandName: PropTypes.string.isRequired,
    setPreferredBrand: PropTypes.func.isRequired,
  };

export default NonBrandItem;
