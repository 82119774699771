import { OFFER_STATUS, STATUS, CATEGORY } from '../constants/quoteConstants';

const ACCEPTED_STATUSES = [
  STATUS.ACCEPTED,
  STATUS.SHIPPED,
  STATUS.PENDING_SHIPMENT,
  STATUS.PENDING_PAYMENT,
  STATUS.RECEIVED,
  STATUS.DELIVERED,
];

const REJECTED_STATUSES = [STATUS.REJECTED, STATUS.UNWANTED, STATUS.TRASH];

const SHIPPING_STATUSES = [
  STATUS.SHIPPED,
  STATUS.DELIVERED,
  STATUS.PARTIALLY_RECEIVED,
  STATUS.RECEIVED,
];

export const getOfferStatus = ({ process_status: status, paid }) => {
  if (paid) return OFFER_STATUS.PAID;
  if (status === STATUS.OFFER_SENT) return OFFER_STATUS.SENT;
  if (REJECTED_STATUSES.includes(status)) return OFFER_STATUS.REJECTED;
  if (ACCEPTED_STATUSES.includes(status)) return OFFER_STATUS.ACCEPTED;
  return OFFER_STATUS.PENDING;
};

export const isInShippingStatus = ({ process_status: status }) => SHIPPING_STATUSES.includes(status);

const quoteItemsTotalReducer = (currentValue, item) => currentValue
  + (item.category === 'UNWANTED' || item.category === 'NOT_DECIDED'
    ? 0
    : item.price * item.quantity);
export const calculateQuoteItemsTotal = (quote) => quote.items.reduce(quoteItemsTotalReducer, 0);

export const mapItemCategory = (category) => {
  switch (category) {
    case CATEGORY.UNWANTED:
      return 'Unwanted';
    case CATEGORY.WANTED:
      return 'Wanted';
    case CATEGORY.ACCEPTABLE:
      return 'Accepted';
    default:
      return 'Not decided';
  }
};

const filterQuoteItemsByCategory = (category, quotes) => quotes.filter((quote) => quote.category === category);

export const splitQuoteItemsByCategory = (quotes) => {
  const splittedQuotes = {};
  Object.entries(CATEGORY).forEach(([key, category]) => splittedQuotes[key] = filterQuoteItemsByCategory(category, quotes));
  return splittedQuotes;
};

const ORIGINAL_LABEL_REGEX = /original/ig;

export const composeProductLabel = (productOriginalName, isOriginal) => (isOriginal ? productOriginalName : productOriginalName?.replace?.(ORIGINAL_LABEL_REGEX, 'Compatible'));

export const buildManualProduct = (userInput, brand) => ({
  label: userInput,
  sku: '',
  requires_expiration_date: false,
  attributes: {
    brand,
    image: {
      thumbnail: undefined
    },
    product_expires: false,
  }
});

export const flattenBrands = (brands) => Object.entries(brands).flatMap(([groupName, subBrands]) => [
  groupName,
  ...subBrands,
]);

export const formatSku = (sku) => (sku ? sku.replace(/-pv/gi, ' (Older Style Box)') : '');
export const formatLabel = (label) => label.replace(/-pv/gi, '');
