import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import useIsMobileView from '../../../../hooks/useIsMobileView';

const AddItemsHeader = () => {
  const isMobile = useIsMobileView();

  return <Box
    pt={4}
    mx={isMobile ? 2 : 0 }
  >
    <Typography
      variant="h2"
      sx={{
        fontFamily: 'Lexend,sans-serif',
        fontSize: '52px !important',
        fontWeight: 700,
        lineheight: '52px',
        letterspacing: '-1px',
        marginBottom: '12px',
        textAlign: 'center',
        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)"
      }}
    >
      Selling Toner Made Easy
    </Typography>
    <Typography
      variant="h2"
      sx={{
        fontFamily: 'Yellowtail, sans-serif',
        fontSize: '32px !important',
        marginBottom: '24px',
        textAlign: 'center'
      }}
      color="black !important"
    >It's as easy as 1, 2, 3!</Typography>
  </Box>;
};

export default AddItemsHeader;
