import { Fragment, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Checkbox,
  Dialog,
  DialogContent,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import useIsMobileView from '../../../hooks/useIsMobileView';

import { CREATE_CONDITIONS, conditionValueToLabel } from '../../../constants/quote/create/conditions';

const ItemConditionSelect = (props) => {
  const { value, onChange } = props;

  const [openItemConditionSelect, setOpenItemConditionSelect] = useState(false);

  const handleSelectChange = (conditionValue) => {
    setOpenItemConditionSelect(false);
    onChange(conditionValue);
  };

  /** Mobile */
  const isMobile = useIsMobileView();

  return (
    <>
      <Dialog
        open={openItemConditionSelect}
        onClose={() => setOpenItemConditionSelect(false)}
        className="item-condition-select-dialog"
      >
        <DialogContent
          sx={{
            width: isMobile ? '100%' : '400px',
            paddingLeft: '0',
            paddingRight: '0',
            backgroundColor: '#F0F0F0',
          }}
        >
          <Box
            sx={{
              textAlign: 'end',
              position: 'static',
              top: '10px',
              zIndex: 3,
            }}
          >
            <Button
              sx={{
                backgroundColor: 'transparent',
                color: 'black',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: 'black',
                  boxShadow: 'none',
                },
              }}
              color="success"
              variant="contained"
              onClick={() => {
                setOpenItemConditionSelect(false);
              }}
            >
              X
            </Button>
          </Box>

          {CREATE_CONDITIONS.map((condition) => {
            return (
              <Fragment key={condition.value}>
                <Box
                  className="item-condition-select-option-box"
                  onClick={() => handleSelectChange(condition.value)}
                  marginRight="1em"
                  marginLeft="1em"
                >
                  <Box sx={{display: "flex", alignItems: "center"}}>
                    <Checkbox
                      className='item-condition-select-checkbox'
                      sx={{color: "#00A7EE", paddingLeft: 0}}
                      checked={value === condition.value}
                      disabled={true}
                    />
                    <Typography
                      color="black"
                      fontFamily="'Lexend', sans-serif"
                      fontSize="18px"
                      fontWeight={500}
                      display="inline"
                    >
                      {condition.title}
                    </Typography>
                  </Box>
                  <Grid container alignItems="end">
                    <ul
                      className={
                        condition.image === null
                          ? ''
                          : 'item-condition-select-feature-ul'
                      }
                    >
                      {condition.features.map((feature, index) => {
                        return (
                          <li
                            key={index}
                            className="item-condition-select-feature-li"
                          >
                            {feature}
                          </li>
                        );
                      })}
                    </ul>
                    {condition.image !== null && (
                      <Box
                        display="flex"
                        justifyContent="right"
                        flexGrow={1}
                        height="fit-content"
                      >
                        <img
                          src={condition.image}
                          alt="condition"
                          width={isMobile ? '120px' : '130px'}
                        />
                      </Box>
                    )}
                  </Grid>
                </Box>
                {/* Don't show the last one */}
                <hr
                  className="item-condition-select-separator"
                  hidden={condition.value === 'D'}
                />
              </Fragment>
            );
          })}
        </DialogContent>
      </Dialog>

      <Select
        className="item-condition-select"
        displayEmpty={true}
        renderValue={(value) => (
          <Typography
            fontFamily="'Lexend', sans-serif"
            fontSize="14px"
            color="#939393"
          >
            {value.length === 0 ? 'Select' : conditionValueToLabel(value)}
          </Typography>
        )}
        value={value}
        open={false}
        onOpen={() => setOpenItemConditionSelect(true)}
        onClose={() => setOpenItemConditionSelect(false)}
      >
        {CREATE_CONDITIONS.map((brand) => (
          <MenuItem key={brand.value} value={brand.value}>
            <span>{brand.label}</span>
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default ItemConditionSelect;
