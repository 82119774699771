import { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';

const ItemQuantityField = (props) => {
  const { value, onChange } = props;

  const [currentValue, setCurrentValue] = useState(value);

  const handlePlusButtonClick = () => {
    const newValue = currentValue + 1;
    setCurrentValue(newValue);
    onChange(newValue);
  };

  const handleMinusButtonClick = () => {
    const newValue = Math.max(1, currentValue - 1);
    setCurrentValue(newValue);
    onChange(newValue);
  };

  const handleNumberChange = (event) => {
    const newInput = event.target.value;
    const newNumber = Number(newInput);
    if (!isNaN(newNumber)) {
      setCurrentValue(newNumber);
      onChange(newNumber);
    }
  }

  return (
    <Box position="relative">
      <Button
        className="item-quantity-field-minus-button"
        onClick={handleMinusButtonClick}
      >
        -
      </Button>
      <TextField
        className="item-quantity-field"
        disabled={false}
        value={currentValue}
        onChange={handleNumberChange}
      />
      <Button
        className="item-quantity-field-plus-button"
        onClick={handlePlusButtonClick}
      >
        +
      </Button>
    </Box>
  );
};

export default ItemQuantityField;
