import PropTypes from 'prop-types';
import { useGetProductPageQuery } from '../../../../store/apiSlice/productSlice';
import { useEffect, useState, useRef } from 'react';
import { Grid, Box, Typography, TextField } from '@mui/material';
import ProductSearcherListDesktop from './ProductSearcherListDesktop';
import ProductSearcherListMobile from './ProductSearcherListMobile';
import { flattenBrands } from '../../../../utils/quoteUtils';
import useIsMobileView from '../../../../hooks/useIsMobileView';
import ProductSearcherArrowImage from './ProductSearcherArrowImage';

const SquareWrapper = ({ children, ...props }) => (
  <Box display="flex" flexDirection="column" {...props}>
    {children}
  </Box>
);

/** Components */
const ProductSearcher = (props) => {
  const onAddItem = props.onAddItem;
  const brands = props.brands;
  const lineItems = props.lineItems;

  /** productSearchText */
  const [productSearchText, setProductSearchText] = useState('');
  const isProductSearchTextEmpty = productSearchText.length === 0;
  const onProductSearchChange = (event) => {
    setProductSearchText(event.target.value);
    setPageNumber(0);
  };

  /** pageNumber */
  const [pageNumber, setPageNumber] = useState(0);

  /** pageSize */
  const [pageSize, setPageSize] = useState(null);

  /** Product query */
  // size: pageSize if pageSize is set (used in the mobile view);
  // on destkop: 4 if the first product based on the search text is present, 5 otherwise.
  let size;
  if (pageSize !== null) {
    size = pageSize;
  } else {
    size = pageNumber === 0 && productSearchText.length > 0 ? 4 : 5;
  }
  let { data: productsPage, isLoading: isLoadingProducts } =
    useGetProductPageQuery({
      page: pageNumber,
      size: size,
      brand: '',
      query: productSearchText,
    });

  // If there is something written on the product search text, make the first product of the first page be a new product with the name of the search text.
  if (
    productsPage !== undefined &&
    productSearchText.length > 0 &&
    pageNumber === 0
  ) {
    productsPage = JSON.parse(JSON.stringify(productsPage));
    productsPage.data.content = [
      { label: productSearchText.toLocaleUpperCase() },
      ...productsPage.data.content,
    ];
  }

  const onAddItemHandler = (product) => {
    // If the product is a product that does not exist in the database,
    // add a product with the default data.
    if (product.id === undefined) {
      return onAddItem({
        product: undefined,
        userInput: product.label.toLocaleUpperCase(),
        brand: '',
        quantity: 1,
        condition: '',
        key: Date.now(),
        expirationDate: null,
        boxDate: null,
      });
    }

    // Use the brand associated with the product, or the preferred brand (HP)
    // if no associated brand is found.
    const productBrand = product.attributes.brand;
    return onAddItem({
      product: product,
      userInput: typeof product === 'string' ? product : product.userInput,
      brand: '',
      quantity: 1,
      condition: '',
      key: Date.now(),
      expirationDate: null,
      boxDate: null,
    });
  };

  /** Mobile */
  const isMobile = useIsMobileView();

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <Grid
      container
      direction="column"
      sx={{ backgroundColor: '#EAEAEA', padding: '2em 0 20px' }}
    >
      {/**  Find your items text and image. */}
      <Box sx={{ textAlign: 'center', marginBottom: '2em', width: '100%' }}>
        <img
          className="product-searcher-icon-printer"
          src="https://cash4toners.com/wp-content/themes/cash4toners/svg/printer.svg"
          alt="printer"
          width="20"
          height="21"
        />
        <Typography
          sx={{
            display: 'inline',
            font: 'bold 22px "Lexend", sans-serif',
            marginLeft: '0.6em',
          }}
        >
          Step 1: Find Your Items
        </Typography>
      </Box>

      {/**  Product searcher */}
      <Box sx={{ textAlign: 'center', marginBottom: '12px' }}>
        <TextField
          className="product-searcher-text-field c4t-search"
          placeholder="Search by SKU or Cartridge Number"
          sx={isMobile ? { width: '90%' } : { minWidth: '400px', width: '70%' }}
          onChange={onProductSearchChange}
        />
        {productsPage !== undefined && productSearchText !== '' && (
          <Typography color="black !important" fontFamily={"'Lexend', sans-serif"} fontSize="15px" paddingTop="10px !important" marginBottom="0 !important">
            {productsPage.data.totalElements + 1} products found.
          </Typography>
        )}
      </Box>

      {/* Empty product list */}
      {!isLoadingProducts && isProductSearchTextEmpty && (
        <>
          <Grid
            container
            direction="row"
            flexWrap="nowrap"
            sx={{ alignItems: 'center', paddingLeft: isMobile ? '5%' : '15%' }}
          >
            <Box>
              <ProductSearcherArrowImage />
            </Box>
            <Box>
              <Typography sx={{ font: "bold 24px 'Lexend', sans-serif" }} color="#595959 !important">
                {lineItems.length === 0
                  ? 'Start here!'
                  : 'Add more items to your list!'}
              </Typography>
              <Typography color="#595959 !important" sx={{ font: "normal 20px 'Lexend', sans-serif !important" }}>
                Find your items by entering the SKU, cartridge number or
                barcode.
              </Typography>
            </Box>
          </Grid>
        </>
      )}

      {/* Product list with at least one product - desktop */}
      {!isLoadingProducts && !isProductSearchTextEmpty && !isMobile && (
        <ProductSearcherListDesktop
          onCarouselNext={() =>
            setPageNumber(
              Math.min(productsPage.data.totalPages - 1, pageNumber + 1)
            )
          }
          onCarouselPrev={() => setPageNumber(Math.max(0, pageNumber - 1))}
          onClickAddItem={(product) => onAddItemHandler(product)}
          productsPage={productsPage}
        />
      )}

      {/* Product list with at least one product - mobile */}
      {!isLoadingProducts && !isProductSearchTextEmpty && isMobile && (
        <ProductSearcherListMobile
          onScrollNext={() => {
            setPageSize(productsPage.data.content.length + 5);
          }}
          onClickAddItem={(product) => onAddItemHandler(product)}
          productsPage={productsPage}
        />
      )}
    </Grid>
  );
};

export default ProductSearcher;
ProductSearcher.propTypes = {
  onAddItem: PropTypes.func.isRequired,
  brands: PropTypes.object.isRequired,
  lineItems: PropTypes.array.isRequired,
};
SquareWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
