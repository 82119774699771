export class ApiError extends Error {
  error = '';

  status = '';

  trace = '';

  path = '';

  timestamp = '';

  apiMessage = '';

  constructor(error, message, status, apiMessage = '') {
    super(message ?? error.error);
    this.apiMessage = apiMessage;
    if (message === undefined) {
      this.error = error.error;
      this.message = error.message;
      this.status = error.status;
      this.path = error.path;
      this.timestamp = error.timestamp;
      return this;
    }
    this.error = error;
    this.message = message;
    this.status = status;
    this.timestamp = new Date().getTime();
    return this;
  }
}

const ERRORS = {
  API_ERRORS: {
    NO_TOKEN: new ApiError(
      'noTokenException',
      'No token in local storage',
      401
    ),
    UNAUTHORIZED: (apiMessage) => new ApiError(
      'unauthorized Exception',
      'Creedentials not valid',
      401,
      apiMessage
    ),
    NOT_FOUND: (apiMessage) => new ApiError('notFound', 'Ressource not found', 404, apiMessage),
    SERVER_ERROR: (status, apiMessage) => new ApiError('serverError', 'Server error', status, apiMessage),
    FORBIDDEN: (apiMessage) => new ApiError('forbiddenError', 'Forbidden', 403, apiMessage),
    BAD_REQUEST: (apiMessage) => new ApiError(
      'malformedRequestError',
      'Bad request error',
      400,
      apiMessage
    ),
    UNKNOWN_ERROR: (status, apiMessage) => new ApiError('unkownError', 'Unknown error', status, apiMessage),
  },
};

export default ERRORS;
