import { Button, Card, CardActions, CardMedia, CardContent, Typography, Grid } from "@mui/material";
import { useEffect } from "react";
import Carousel from "react-material-ui-carousel";

const ProductSearcherListDesktop = (props) => {
  const {productsPage, onCarouselNext, onCarouselPrev, onClickAddItem} = props;

  useEffect(() => {
    const hideBefore = productsPage.data.first
    const hideAfter = productsPage.data.last
          document.querySelectorAll('[data-testid="NavigateBeforeIcon"]')[0]
          .parentElement
          .parentElement
          .setAttribute("style", hideBefore ? "visibility: hidden;" : "visibility: initial;")

          document.querySelectorAll('[data-testid="NavigateNextIcon"]')[0]
          .parentElement
          .parentElement
          .setAttribute("style", hideAfter ? "visibility: hidden;" : "visibility: initial;")
  });

  return <Carousel
        next={ onCarouselNext}
        prev={onCarouselPrev}
        autoPlay={false}
        navButtonsAlwaysVisible={true}
        animation="slide"
        sx={{ height: '350px' }}
      >
        <Grid
          container
          direction="row"
          sx={{ rowGap: '20px', columnGap: '2%', justifyContent: 'center' }}
        >
          {/* Product card */}
          {productsPage.data.content.map((product, index) => {
            return (
              <Card
                key={index}
                sx={{
                  backgroundColor: 'white',
                  minWidth: '185px',
                  minHeight: '271px',
                  width: '12%',
                  boxShadow: 'none !important'
                }}
              >
                <CardMedia
                  sx={{ padding: '20px', minHeight: '185px !important' }}
                  component="img"
                  alt={`${product.label} image`}
                  height="140"
                  image={
                    product.attributes?.image?.thumbnail ??
                    "https://cash4toners.com/wp-content/themes/cash4toners/img/no-img.jpg"
                  }
                />
                <CardContent sx={{ height: '86px', paddingTop: '0' }}>
                  <Typography
                    color="black !important"
                    sx={{
                      font: "500 16px 'Lexen', sans-serif",
                      textAlign: 'center',
                    }}
                  >
                    <span style={{fontWeight: "bold"}}>{product.sku}</span> {product.label.replace(product.sku, "")}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                  <Button
                    className="c4t-add-item-to-list"
                    sx={{
                      color: '#0245B6',
                      borderColor: '#0245B6',
                      font: "14px 'Lexend', sans-serif",
                      minWidth: '80px',
                      minHeight: '29px',
                      padding: '7px !important',
                      marginBottom: '15px !important'
                    }}
                    size="small"
                    variant="outlined"
                    onClick={() => onClickAddItem(product)}
                  >
                    + Add
                  </Button>
                </CardActions>
              </Card>
            );
          })}
        </Grid>
      </Carousel>;
};

export default ProductSearcherListDesktop;
