import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { accordionSummaryClasses } from '@mui/material';
import merge from 'lodash/merge';
import Lexend from '../assets/fonts/Lexend-Regular.ttf';
import LexendMedium from '../assets/fonts/Lexend-Medium.ttf';
import LexendSemiBold from '../assets/fonts/Lexend-SemiBold.ttf';
import LexendBold from '../assets/fonts/Lexend-Bold.ttf';
import LexendExtraBold from '../assets/fonts/Lexend-ExtraBold.ttf';
import InterThin from '../assets/fonts/Inter-Thin.ttf';
import InterExtraLight from '../assets/fonts/Inter-ExtraLight.ttf';
import InterLight from '../assets/fonts/Inter-Light.ttf';
import Inter from '../assets/fonts/Inter-Regular.ttf';
import InterMedium from '../assets/fonts/Inter-Medium.ttf';
import InterSemiBold from '../assets/fonts/Inter-SemiBold.ttf';
import InterBold from '../assets/fonts/Inter-Bold.ttf';
import InterExtraBold from '../assets/fonts/Inter-ExtraBold.ttf';
import InterBlack from '../assets/fonts/Inter-Black.ttf';
import AksharRegular from '../assets/fonts/Akshar-Regular.ttf';

const baseOptions = {
  direction: 'ltr',
  typography: {
    fontFamily: 'Inter, Lexend, Arial'
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'rgb(32, 171, 235)',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 100;
          src: url("${InterThin}") format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 200;
          src: url("${InterExtraLight}") format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 300;
          src: url("${InterLight}") format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          src: url("${Inter}") format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          src: url("${InterMedium}") format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 100;
          src: url("${InterThin}") format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          src: url("${InterSemiBold}") format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 700;
          src: url("${InterBold}") format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 800;
          src: url("${InterExtraBold}") format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 900;
          src: url("${InterBlack}") format('truetype');
        }
        @font-face {
          font-family: 'Akshar';
          font-style: normal;
          font-weight: 400;
          src: url("${AksharRegular}") format('truetype');
        }
        @font-face {
          font-family: 'Lexend';
          font-style: normal;
          font-weight: 400;
          src: url(${Lexend}) format('truetype');
        }
        @font-face {
          font-family: 'Lexend';
          font-style: normal;
          font-weight: 500;
          src: url(${LexendMedium}) format('truetype');
        }
        @font-face {
          font-family: 'Lexend';
          font-style: normal;
          font-weight: 600;
          src: url(${LexendSemiBold}) format('truetype');
        }
        @font-face {
          font-family: 'Lexend';
          font-style: normal;
          font-weight: 700;
          src: url(${LexendBold}) format('truetype');
        }
        @font-face {
          font-family: 'Lexend';
          font-style: normal;
          font-weight: 800;
          src: url(${LexendExtraBold}) format('truetype');
        }
        * {
          boxSizing: 'border-box';
        }        
        html {
          MozOsxFontSmoothing: grayscale;
          WebkitFontSmoothing: antialiased;
          height: 100%;
          width: 100%;
        }        
        body {
          height: 100%;
        }
        #root {
          height: 100%;
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%',
        },
        body: {
          height: '100%',
        },
        '#root': {
          height: '100%',
        },
        '#nprogress .bar': {
          zIndex: '2000 !important',
        },
        thead: {
          breakInside: 'auto',
        },
        [.${accordionSummaryClasses.expandIconWrapper}]: {
          border: 'solid 1px',
          borderRadius: '50%',
        },
        `
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: 'subTitle',
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      padding: '16px 27px 31px'
    }
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgb(255, 255, 255)',
        borderRadius: 8,
        boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
      }
    }
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 'auto',
        marginRight: '16px',
      },
    },
  }
};

const themeOptions = {
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 15,
          lineHeight: '30px',
          height: 'auto',
          padding: 0,
          '&::placeholder': {
            opacity: 0.86,
            color: '#5f6670',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: '10px 27px'
        },
        input: {
          padding: 0,
        },
        notchedOutline: {
          borderColor: '#bec5d0',
          borderWidth: '1px !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
        rounded: {
          borderRadius: 8,
          boxShadow: '0px 5px 10px rgba(0,0,0,0.1)'
        }
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '10px 27px'
        },
        input: {
          padding: '0 !important'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          padding: 0
        },
        select: {
          padding: '10px 27px'
        }
      }
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: 'rgb(32, 171, 235) !important',
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#092f6f',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#000000',
      contrastText: '#ffffff',
    },
    text: {
      secondary: '#40506B',
      primary: '#092f6f',
      disabled: '#B7BFCB',
      hint: '#40506b',
    },
    background: {
      default: 'white',
      paper: '#f0f0f0',
      darkPaper: '#F5F5F5',
      dark: 'rgb(205, 227, 224)',
    },
    divider: '#8c97a9',
    info: {
      main: 'rgb(68, 178, 96)',
    },
    success: {
      contrastText: 'white',
      main: 'rgb(32, 171, 235)',
    },
    // Custom Colors for Icons
    icons: {
      main: 'rgb(25, 37, 95)',
      unselected: 'rgb(217, 222, 218)',
      secondary: 'red',
    },
    // Avatar Colors
    avatar: {
      border: 'rgb(232, 242, 241)',
    },
    // Custom
    catskillWhite: {
      main: '#EBF5F4'
    },
    // Colors for chips
    chipSuccess: {
      main: '#008000',
      contrastText: '#ffffff',
    },
    chipProblem: {
      main: '#FF0000',
      contrastText: '#ffffff',
    },
    chipNotRecieved: {
      main: '#FFD700',
      contrastText: '#092f6f',
    },
    progressBar: {
      main: '#0F91D2'
    },
    highlight: {
      main: '#59BB67'
    }
  },
  typography: {
    fontSize: 15,
    fontFamily: 'Inter, Lexend, Helvetica, Arial',
    lineHeight: '30px',
    mainTitle: {
      fontSize: 42,
      fontWeight: 600,
      lineHeight: '52px'
    },
    title: {
      fontSize: 32,
      lineHeight: '40px',
      fontWeight: 600
    },
    subTitle: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '30px',
    },
    subTitleMedium: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '30px',
    },
    formLabel: {
      fontWeight: 500,
      marginBottom: '7px',
    },
    paragraph: {
      fontSize: 15,
      fontWeight: 400,
    },
    paragraphMedium: {
      fontSize: 15,
      fontWeight: 500,
    },
    paragraphBold: {
      fontSize: 15,
      fontWeight: 600,
    },
    paragraphBold2: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '19.36px',
    },
    h1: {
      fontSize: 46,
      lineHeight: 1.25,
      letterSpacing: '-1.1px',
    },
    h2: {
      fontSize: 30,
    },
    h3: {
      fontSize: 24,
      letterSpacing: '-0.76px',
    },
    h4: {
      fontSize: 18,
    },
    subtitle1: {
      fontWeight: 300,
      fontSize: 15,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontWeight: 300,
      fontSize: 15,
      lineHeight: '25px',
    },
    body1: {
      fontWeight: 300,
      fontSize: 15,
      lineHeight: '25px',
    },
    button: {
      letterSpacing: '0.00em',
      fontSize: 15,
    },
    caption: {
      fontSize: 17,
    },
  },
  props: {
    MuiAppBar: {
      color: 'secondary',
    },
  },
  shape: {
    borderRadius: 25,
  },
  spacing: 8,
};

const createCustomTheme = () => {
  let theme = createTheme(merge({}, baseOptions, themeOptions));

  theme = responsiveFontSizes(theme);

  return theme;
};

export default createCustomTheme();
