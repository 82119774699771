import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import C4tApi from '../api/c4t';
import { setToken, deleteToken, hasToken } from '../utils/httpUtils';
import { sendGTMEvent } from '../utils/GoogleUtils';

const identifySmartlook = (user) => {
  window.smartlook?.('identify', user.email, {
    name: `${user.first_name} ${user.last_name}`,
  });
};

const anonymizeSmartlook = () => {
  window.smartlook?.('anonymize');
};

const { account: accountApi } = C4tApi;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  CHANGE: (state, action) => ({
    ...state,
    user: action.payload,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateUser: () => Promise.resolve,
  register: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        if (hasToken()) {
          const user = await (await accountApi.me().call()).json();

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        deleteToken();
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        anonymizeSmartlook();
      }
    };

    initialize();
  }, []);

  const login = async (emailInput, password) => {
    const { token } = await (
      await accountApi.login(emailInput, password).call()
    ).json();

    const user = await (await accountApi.me(token).call()).json();

    setToken(token);
    sendGTMEvent('login');
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
    // function to update wordpress header
    window?.refreshLogin();
    identifySmartlook(user);
  };

  const logout = async () => {
    deleteToken();
    dispatch({ type: 'LOGOUT' });
    // function to update wordpress header
    window?.refreshLogin();
    anonymizeSmartlook();
  };

  const updateUser = async (personalInfo) => {
    const newUser = await (
      await accountApi.updatePersonalInformation(personalInfo).call()
    ).json();
    dispatch({ type: 'CHANGE', payload: newUser });
  };

  const register = async (
    firstName,
    lastName,
    email,
    password,
    phone,
    address,
    company,
  ) => {
    const { token: accessToken } = await (
      await accountApi
        .signUp(
          firstName,
          lastName,
          email,
          password,
          phone,
          address,
          company,
        )
        .call()
    ).json();
    const user = await (await accountApi.me(accessToken).call()).json();

    setToken(accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        updateUser,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
